  .App {
    display: flex;
    text-align: center; /* Adjust the text alignment if needed */
    min-height: 100vh;
  }

.test {
  max-height: 40vh;
}

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
  background-color: #282c34;  
  width: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; /* This centers the content horizontally within the .App-header itself */
  justify-content: center; /* This centers the content vertically within the .App-header */
  font-size: calc(10px + 2vmin);
  color: white;
  margin-left: auto; /* This offsets the position to account for the sidebar */
  margin-right: auto;
  margin-bottom: auto;
  flex-grow: 1; 
}


  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  /* Adjusted CSS for narrower input and autocomplete dropdown */

  * {
    box-sizing: border-box; /* Ensure padding and border are included in total width and height */
  }

  .descr{
    width:30%;
    align-items: center;
    justify-content: center;
  }

  .autocomplete {
    position: relative;
    display: inline-block;
    width: 30%; /* Set the width to be 80% of its parent */
    margin: 0 auto; /* Center align the autocomplete block */
  }

  input[type="text"] {
    width: 100%; /* Make input width fill the .autocomplete container */
    padding: 10px;
    border: 1px solid #d4d4d4;
    border-radius: 4px 4px 0 0; /* Rounded corners at the top */
    font-size: 16px; /* Readable font size */
  }

  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none; /* Remove top border for a continuous look */
    z-index: 99;
    bottom: 100%;
    left: 0;
    right: 0;
    border-radius: 0 0 4px 4px; /* Rounded corners at the bottom */
    background-color: #fff;
    max-height: 125px; /* Limit dropdown height */
    overflow-y: auto; /* Scrollbar for overflow */
    width: 100%; /* Dropdown matches input width */
  }

  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #d4d4d4; /* Separate items visually */
    color: #282c34; /* Text color for visibility */
    font-size: 16px; /* Consistent font size with input */
  }

  .autocomplete-items div:last-child {
    border-bottom: none; /* No bottom bo  rder for the last item */
  }

  .autocomplete-items div:hover {
    background-color: #f1f1f1; /* Hover state background color */
  }

  .App-sidebar {
    position: fixed; /* Position the sidebar relative to the viewport */
    top: 0; /* Align the top of the sidebar with the top of the viewport */
    left: 0; /* Align the left of the sidebar with the left of the viewport */
    background-color: #282c34;
    color: white;
    padding: 20px;
    width: 250px; /* Set a fixed width for the sidebar */
    height: 100vh;
    box-sizing: border-box;
    z-index: 100; /* Ensure the sidebar is above other content */
  }

  .green-text {
    color: green;
  }
  

  /* Add styles for nav links in the sidebar for better visibility */
  .App-sidebar nav ul {
    list-style-type: none; /* Remove bullet points */
    padding: 0; /* Remove default padding */
  }

  .App-sidebar nav ul li {
    padding: 10px 0; /* Add some vertical padding for each link */
  }

  .App-sidebar nav ul li a {
    color: #61dafb; /* Use the same color as .App-link for consistency */
    text-decoration: none; /* Remove underline from links */
  }

  .App-sidebar nav ul li a:hover {
    text-decoration: underline; /* Underline on hover for a visual cue */
  }